import React from 'react'
import {SEO, Datepicker, Select, Button, Input, Modal} from 'components'
import {AdminLayout, InvoicesPlan, InvoicesPlansToCompare, InvoicesPlanDetail} from 'widgets'
import moment from 'moment'
import {DESTINIES, quitLoading, setLoading, showError, showSuccess} from 'utils'
import { InvoiceService, PlanService } from 'services'
import {Plan} from 'models'

type Form = {
    since: Date,
    until: Date | undefined,
    destiny_id: string,
    passengers_ages: number[]
}
type PlanSubmitPlan = {plan: Plan, coin: string, price: number}
type SubmitPlan = {
    email: string,
    form: Form,
    plans: PlanSubmitPlan[] | null,
}
export default () => {
    const defaultAge = 18
    const IFormState: Form = {
        since: moment().toDate(),
        until: undefined,
        destiny_id: '',
        passengers_ages: [defaultAge]
    }
    const ISubmitPlanState: SubmitPlan = {
        email: '',
        form: IFormState,
        plans: null
    }
    const [form, setForm] = React.useState<Form>(IFormState)
    const [plans, setPlans] = React.useState<Plan[]>([])
    const [plansToCompare, setPlansToCompare] = React.useState<number[]>([])
    const [visibleComparePlans, setVisibleComparePlans] = React.useState<boolean>(false)
    const [visibleDetail, setVisibleDetail] = React.useState<boolean>(false)
    const [visibleSubmitPlan, setVisibleSubmitPlan] = React.useState<boolean>(false)
    const [detailPlan, setDetailPlan] = React.useState<Plan | null>(null)
    const [submitPlan, setSubmitPlan] = React.useState<SubmitPlan>(ISubmitPlanState)
    const [isPlanToSale, setIsPlanToSale] = React.useState<boolean>(false)
    
    const change = (key: keyof Form, value: string | Date | number[]) => {
        const _form: Form = {
            ... form,
            [key]: value
        }
        setForm(_form);
    }
    const addPassenger = () => {
        let passengers_ages = [... form.passengers_ages]
        passengers_ages.push(defaultAge)
        const _form = {
            ... form,
            passengers_ages
        }
        setForm(_form)
    }
    const deletePassenger = () => {
        let passengers_ages = [... form.passengers_ages]
        passengers_ages.splice(passengers_ages.length-1, 1)
        const _form = {
            ... form,
            passengers_ages
        }
        setForm(_form)
    }
    const submit = async () => {
        try {
            setLoading()
            const _plans = await InvoiceService.get(form)
            setPlans(_plans)
        } catch (error) {
            showError(error?.response?.data?.msg)
        }finally{
            quitLoading()
        }
    }
    const useThisPlan = async (plans: Array<Plan & {isSelected?: boolean}>) => {
        onClose()
        const _plans = plans.map((element: Plan) => {
            const amount = element.price.trim().split(' ')[0]
            const coin = element.price.trim().split(' ')[1]
            return {
                plan: element,
                price: parseFloat(amount),
                coin,
            }
        })
        const _submitPlan: SubmitPlan = {
            plans: _plans,
            form,
            email: ''
        }
        setSubmitPlan(_submitPlan)
        if(isPlanToSale)
            setIsPlanToSale(false)
        setVisibleSubmitPlan(true)
    }
    const sendSale = async () => { //Envio de plan directo por ser una venta
        try {
            setLoading()
            const {msg} = await InvoiceService.sendSale(submitPlan)
            setSubmitPlan(ISubmitPlanState)
            setDetailPlan(null)
            setIsPlanToSale(false)
            setVisibleSubmitPlan(false)
            showSuccess(msg)
        } catch (error) {
            showError(error?.response?.data?.msg)
        }finally{
            quitLoading()
        }
    }
    const openSale = async (plan: Plan) =>{
        const amount = plan.price.trim().split(' ')[0]
        const coin = plan.price.trim().split(' ')[1]
        const _plan = {
            plan,
            price: parseFloat(amount),
            coin,
        }
        const _submitPlan: SubmitPlan = {
            ... ISubmitPlanState,
            plans: [_plan],
            form
        }
        setIsPlanToSale(true)
        setSubmitPlan(_submitPlan)
        setVisibleSubmitPlan(true)
    }
    const checkAndSetPlansToCompare = (element: Plan, isAdded: boolean) => {
        let _plans = [... plansToCompare]
        if(!isAdded){
            _plans.push(element.id)
        }else{
            const index = _plans.findIndex((_element: number) => _element === element.id)
            if(index !== -1){
                _plans.splice(index, 1)
            }
        }
        setPlansToCompare(_plans)
    }
    const comparePlans = () => {
        setVisibleComparePlans(true)
    }
    const viewPlanDetail = async (element: Plan) => {
        setDetailPlan(element)
        setVisibleDetail(true)
    }
    const onClose = () => {
        if(visibleComparePlans)
            setVisibleComparePlans(false)
        if(visibleDetail)
            setVisibleDetail(false)
        if(visibleSubmitPlan)
            setVisibleSubmitPlan(false)
    }
    const changeSubmitPlan = (key: keyof PlanSubmitPlan, value: string | number, i: number) => {
        let _plans = submitPlan.plans && [... submitPlan.plans] || null
        if(_plans){
            _plans[i] = {
                ... _plans[i],
                [key]: value
            }
            const _submitPlan: SubmitPlan = {
                ... submitPlan,
                plans: _plans
            }
            setSubmitPlan(_submitPlan)
        }
    }
    const sendPlan = async () => { //Envio de cotizacion principal
        try {
            setLoading()
            const {msg} = await InvoiceService.save(submitPlan)
            setDetailPlan(null)
            setSubmitPlan(ISubmitPlanState)
            setVisibleSubmitPlan(false)
            showSuccess(msg)
        } catch (error) {
            showError(error?.response?.data?.msg)
        }finally{
            quitLoading()
        }
    }
    return(
        <AdminLayout>
            <React.Fragment>
                <SEO
                    title="Cotizaciones"
                />
                <Modal
                    visible={visibleSubmitPlan}
                    onClose={onClose}
                    styles={{
                        overlay:{
                            zIndex: 2
                        },
                        content:{
                            overflowX: 'hidden'
                        }
                    }}
                >
                    <div className="container">
                        <h3 className="text-purple">
                            ¿Esta seguro de generar la {isPlanToSale ? 'venta' : 'cotizacion'} con estos planes?
                        </h3>
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <Input
                                    required
                                    name="email"
                                    type="email"
                                    onChange={(value: string) => setSubmitPlan({
                                        ... submitPlan,
                                        email: value
                                    })}
                                    label="Email del pasajero"
                                    value={submitPlan.email}
                                />
                            </div>
                        </div>
                        {
                            submitPlan.plans?.map((element, i) => {
                                return(
                                    <div
                                        className="row"
                                        key={i}
                                    >
                                        <div className="col-12">
                                            <h4 className="text-purple m-0 pb-1">
                                                {
                                                    element.plan.name
                                                }
                                            </h4>
                                        </div>
                                        <div className="col-md col-sm-12">
                                            <Input
                                                required
                                                name="price"
                                                type="number"
                                                onChange={(value: string) => changeSubmitPlan('price', parseFloat(value), i)}
                                                label="Precio"
                                                value={element.price}
                                            />
                                        </div>
                                        <div className="col-md col-sm-12">
                                            <Input
                                                required
                                                name="coin"
                                                type="text"
                                                onChange={(value: string) => changeSubmitPlan('coin', value, i)}
                                                label="Moneda"
                                                value={element.coin}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="w-100 text-center">
                            <Button
                                label="Enviar"
                                className="primary"
                                onClick={() => isPlanToSale ? sendSale() : sendPlan()}
                            />
                        </div>
                    </div>
                </Modal>
                <Modal
                    visible={visibleComparePlans}
                    onClose={onClose}
                    styles={{
                        overlay:{
                            zIndex: 2
                        },
                        content:{
                            overflowX: 'hidden'
                        }
                    }}
                >
                    <InvoicesPlansToCompare
                        plansToCompare={plansToCompare}
                        plans={plans}
                        useThisPlan={useThisPlan}
                    />
                </Modal>
                <Modal
                    visible={visibleDetail}
                    onClose={onClose}
                    styles={{
                        overlay:{
                            zIndex: 2
                        },
                        content:{
                            maxWidth: '60%',
                            marginLeft: '20%',
                            overflowX: 'hidden'
                        }
                    }}
                >
                    <InvoicesPlanDetail
                        plan={detailPlan}
                    />
                </Modal>
                <div className="invoices">
                    {
                        plansToCompare.length > 1 && (
                            <div
                                className="widget-float text-center bg-dark-gray rounded-pill"
                            >
                                <p
                                    className="text-white m-0 p-1 cursor-pointer"
                                    onClick={() => comparePlans()}
                                >
                                    Comparar planes
                                </p>
                            </div>
                        )
                    }
                    <div className="w-100 text-center">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-4 col-sm-12">
                                    <Datepicker
                                        onChange={(value: Date) => change('since', value)}
                                        value={form.since}
                                        showMonthDropdown
                                        showYearDropdown
                                        label="Inicio de viaje"
                                    />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <Datepicker
                                        onChange={(value: Date) => change('until', value)}
                                        value={form.until}
                                        showMonthDropdown
                                        showYearDropdown
                                        minDate={form.since}
                                        label="Fecha de regreso"
                                    />
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <Select
                                        label="Destino"
                                        name="destiny_id"
                                        onChange={(value: string) => change('destiny_id', value)}
                                        value={form.destiny_id}
                                        options={DESTINIES.map(element => {
                                            return{
                                                label: element.es,
                                                value: element.slug
                                            }
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-sm-12">
                                <h4 className="text-dark-gray m-0 pb-1">Edades de pasajeros</h4>
                                <Button
                                    icon="plus"
                                    className="primary"
                                    onClick={() => addPassenger()}
                                    small
                                />
                                <Button
                                    icon="trash"
                                    className="danger m-1"
                                    small
                                    disabled={form.passengers_ages.length === 1}
                                    onClick={() => deletePassenger()}
                                />
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="row">
                                    {
                                        form.passengers_ages?.map((element, i) => {
                                            return(
                                                <div className="col-md-3 col-sm-4" key={i}>
                                                    <Input
                                                        name="age"
                                                        value={element}
                                                        onChange={(value: string) => {
                                                            let passengers_ages = [... form.passengers_ages]
                                                            passengers_ages[i] = parseInt(value)
                                                            change('passengers_ages', passengers_ages)
                                                        }}
                                                        label={'Pasajero '+(i+1)}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-12">
                                <Button
                                    className="primary"
                                    label="Cotizar"
                                    onClick={() => submit()}
                                />
                            </div>
                        </div>
                        <InvoicesPlan
                            plans={plans}
                            plansToCompare={plansToCompare}
                            useThisPlan={openSale}
                            checkAndSetPlansToCompare={checkAndSetPlansToCompare}
                            viewPlanDetail={viewPlanDetail}
                        />
                    </div>
                </div>
            </React.Fragment>
        </AdminLayout>
    )
}